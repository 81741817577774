.login-wrapper {
  background: linear-gradient(#ffffff, #f5f5f5 28%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: white;
  padding: 24px;
}

.text-center {
  text-align: center;
}