@import 'normalize.css';

:root {
    --primary: #fe2c55;
    --black: #000;
    --white: #fff;
    --text-color: #161823;

    --font-family: 'Inter', 'Arial', sans-serif;

    // Default layout
    --default-layout-header-height: 60px;
    --default-layout-width: 1150px;
    --default-layout-horizontal-spacer: 24px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}
* {
    font-family: var(--font-family) !important;
}
body {
    font-family: var(--font-family) !important;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizespeed;
    color: var(--text-color);
    overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a {
    color: var(--text-color);
    text-decoration: none;
}
.text-bold {
    font-weight: 600;
}
// Custom tippy tooltip
body {
    .tippy-box {
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
        background-color: rgba(84, 84, 84, 0.92);
    }

    .tippy-arrow {
        color: rgba(84, 84, 84, 0.92);
    }

    .tippy-content {
        padding: 8px 9px;
    }

    .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
        top: -8px;
    }
}

:where(.css-dev-only-do-not-override-26rdvq).ant-form-item {
    margin-bottom: 16px;
}

.ant-layout-sider {
    background-color: white !important;
}

.ant-layout-header {
    background: linear-gradient(180deg, #005dd6 0%, #147aff 100%) !important;
    h1 {
        color: white !important;
    }
}
.image-group-preview {
    display: flex;
    width: 100%;
}
.image-group-preview .ant-image {
    margin-right: 12px;
}

.ant-picker {
    width: 100% !important;
}
.form-item-custom {
    margin-bottom: 8px;
}
.form-item-custom label {
    font-weight: 500;
}

.card-center {
    .ant-form-item-label {
        text-align: center !important;
    }
    text-align: center !important;
}

.ubg-green {
    background-color: green !important;
}
.input-time-hours {
    width: 80px !important;
}
.link-color-white {
    color: #fff;
}
.link-color-white:hover {
    color: #ccc;
}
.container-detail img {
  max-width:100%
}